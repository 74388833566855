<template>
  <div class="flex md:mx-12 mt-4 mb-8">
    <div class="w-full m-4 md:m-0">
      <div class="flex mb-2">
        <h2 class="font-semibold">
          {{ title }}
        </h2>
      </div>
      <div>
        <slot name="text"></slot>
      </div>
      <div class="bg-white shadow rounded-lg p-4">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
    },
  };
</script>
