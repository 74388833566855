<template>
  <main>
    <div class="flex items-center justify-center m-4 md:mx-8">
      <img
        src="/img/overview.png"
        alt="">
    </div>
    <div class="flex flex-col md:flex-row md:items-baseline items-stretch md:mx-8">
      <div class="m-4 md:w-full">
        <div class="flex mb-2">
          <h3 class="font-semibold">
            Avarage Energy Price
          </h3>
          <h3 class="text-theme ml-2">
            € {{ graphAverage.toFixed(2) }}
          </h3>
        </div>
        <div class="bg-white shadow rounded-lg p-4">
          <BarChart
            ref="barChart"
            :chart-data="data"
            :options="options" />
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 m-4 md:w-full">
        <Card
          title="Building"
          :loading="loading.building"
          :data="buildingData" />
        <Card
          title="EV Chargers"
          :loading="loading.evChargers"
          :data="EVChargersData" />
        <Card
          title="Solar"
          :loading="loading.solar"
          :data="solarData" />
        <Card
          title="E storage"
          :loading="loading.eStorage"
          :data="eStorageData" />
      </div>
    </div>

    <div class="flex md:mx-12 mt-4 mb-8">
      <div class="w-full m-4 md:m-0">
        <div class="flex mb-2">
          <h2 class="font-semibold">
            Level 0: EV Charging + Energy Monitoring
          </h2>
        </div>
        <div>
          <ul class="list-disc ml-4">
            <li>Monitoring laadsessies</li>
            <li>Monitoring verbruik laadpalen</li>
          </ul>

          <p class="text-gray-600 my-2">
            Bij level 0 zetten we voor u het aantal laadsessies per uur en per dag uiteen versus de energie per laadsessie, per uur en per dag zodat u in staat bent uw energie profiel in te zien, ontzorgen we voor u de betalingen & de backoffice aansluiten van uw EV laadnetwerk.
          </p>
        </div>
        <div class="bg-white shadow rounded-lg p-4">
          <scatter-chart
            :chart-data="levelZeroChartData"
            :options="levelZeroChartOptions " />
        </div>
      </div>
    </div>

    <level-graph-container title="Level 1: Socket Balancing">
      <template #text>
        <span class="inline-flex items-center px-3 py-0.5 rounded-sm text-sm font-medium bg-yellow-100 text-yellow-800">
          (i) Potential extra profit level 2:   €3420,34 / year
        </span>
        <ul class="list-disc ml-4 mt-2">
          <li>Monitoring laadsessies</li>
          <li>Monitoring laadsessie inkomsten</li>
          <li>Realtime weergave van winstgevendheid</li>
          <li>Monitoring verbruik laadpalen</li>
          <li>EPEX energieprijzen</li>
          <li>Weergave mogelijke besparing level 2 Smart EV Grid laadprofiel</li>
        </ul>

        <p class="text-gray-600 my-2">
          Alle functionaliteiten level 0 +
          Bij level 1 zetten we voor u visueel uiteen de energieprijzen per uur versus uw EV laadprofiel van al uw laadpalen versus de hiervoor in rekening gebrachte geldstromen. Middels deze combinatie berekenen we doorlopend & realtime de winstgevendheid van uw laadnetwerk. Tevens calculeren wij voor u of slim laden gebalanceerd op EPEX energieprijzen al interessant voor u is en als het de investering waard is om te schakelen naar Smart EV Grid
        </p>
      </template>
      <scatter-chart
        :chart-data="levelOneChartData"
        :options="levelZeroChartOptions " />
    </level-graph-container>

    <level-graph-container title="Level 2: Location Balancing">
      <template #text>
        <div>
          <span class="inline-flex items-center px-3 py-0.5 rounded-sm text-sm font-medium bg-green-100 text-green-800">
            (i) Potential extra profit level 2:   €3420,34 / year
          </span>
        </div>
        <div>
          <span class="inline-flex items-center px-3 py-0.5 rounded-sm text-sm font-medium bg-yellow-100 text-yellow-800">
            (i) Potential extra profit level 2:   €3420,34 / year
          </span>
        </div>
        <ul class="list-disc ml-4 mt-2">
          <li><span class="font-medium">Actieve sturing</span> laadsessies</li>
          <li><span class="font-medium">Actieve sturing</span> verbruik laadpalen</li>
          <li><span class="font-medium">Actieve sturing</span> middels Smart EV Grid laadprofiel</li>
          <li><span class="font-medium">Actieve trigger</span> EPEX energieprijzen</li>
          <li><span class="font-medium">Optimalisatie</span> van winstgevendheid</li>
          <li>Monitoring laadsessie inkomsten	</li>
        </ul>
        <p class="text-gray-600 my-2">
          Alle functionaliteiten level 0 t/m 1 +
          Bij level 2 analyseren we de aankomst & vertrektijden van uw laadpaal gebruikers, deze data combineren we met de day energieprijzen, waarbij we actief sturen op maximale laadcapaciteit op de voordeligste momenten en minimaal geaccepteerd laadvermogen op de duurste momenten van de dag.
        </p>
      </template>
      <BarChart
        ref="barChart"
        :chart-data="levelTwoChartData"
        :options="levelZeroChartOptions" />
    </level-graph-container>

    <level-graph-container title="Level 3: Solar Charging">
      <BarChart
        ref="barChart"
        :chart-data="levelThreeChartData"
        :options="smartLevelOptions" />
    </level-graph-container>

    <level-graph-container title="Level 4: Optimized Solar Charging">
      <template #text>
        <p class="text-gray-600 my-2">
          Bij negatieve prijzen moet de PV uitgezet worden of boven maximum.
        </p>
      </template>
      <BarChart
        ref="barChart"
        :chart-data="levelFourChartData"
        :options="smartLevelOptions" />
    </level-graph-container>

    <level-graph-container title="Level 5: Optimzed Solar Charging & Storage">
      <template #text>
        <p class="text-gray-600 my-2">
          Gelijktijdigheid aantoning voor HBE’s
        </p>
      </template>
      <BarChart
        ref="barChart"
        :chart-data="levelFiveChartData"
        :options="smartLevelOptions" />
    </level-graph-container>

    <level-graph-container title="Level 6: Virtual Power Plant">
      <template #text>
        <ul class="list-disc ml-4 my-2">
          <li>Smart Battery.</li>
          <li>Bewijsbaar alleen groene energie in batterij ivm HBE.</li>
          <li>Laadregime: als er zon PV retour geleverd wordt op het net</li>
          <li>Ontlaadregime: Als bij laden uit het net geleverd  wordt</li>
        </ul>
      </template>
      <BarChart
        ref="barChart"
        :chart-data="levelSixChartData"
        :options="smartLevelOptions" />
    </level-graph-container>
  </main>
</template>

<script>
  import { BarChart, ScatterChart } from 'vue-chart-3';
  import { Chart, registerables } from 'chart.js';
  import axios from 'axios';
  import Card from '../components/Card.vue';
  import LevelGraphContainer from '../components/LevelGraphContainer.vue';

  Chart.register(...registerables);

  export default {
    components: {
      BarChart,
      ScatterChart,
      Card,
      LevelGraphContainer,
    },
    data() {
      return {
        timer: 0,
        loading: {
          building: true,
          evChargers: true,
          solar: true,
          eStorage: true,
        },
        graphAverage: 0,
        graphAverageValues: {},
        buildingData: {},
        solarData: {},
        eStorageData: {},
        EVChargersData: {},

        levelZeroChartData: {
          labels: [],
          datasets: [
            {
              type: 'line',
              label: 'Total kWh',
              data: [],
              fill: false,
              backgroundColor: 'rgba(7, 38, 56, 1)',
              borderColor: 'rgba(7, 38, 56, 1)',
              yAxisID: 'y1',
            }, {
              type: 'bar',
              label: 'EV Charging',
              data: [],
              backgroundColor: 'rgba(0, 160, 186, 1)',
              yAxisID: 'y',
            }],
        },

        levelZeroChartOptions: {
          scales: {
            y: {
              title: {
                display: true,
                text: 'EV Charging',
              },
              beginAtZero: true,
            },
            y1: {
              title: {
                display: true,
                text: 'kWh',
              },
              position: 'right',
              grid: {
                drawOnChartArea: false,
              },
            },
          },
        },

        data: {
          labels: [],
          datasets: [
            {
              label: 'Energy price',
              data: [],
              datalabels: {
                color: [],
              },
              backgroundColor: [
                'rgba(7, 38, 56, 1)',
              ],
              borderWidth: 1,
            },
          ],
        },

        levelOneChartData: {
          labels: [],
          datasets: [
            {
              type: 'line',
              label: 'Total kWh',
              data: [],
              fill: false,
              backgroundColor: 'rgba(7, 38, 56, 1)',
              borderColor: 'rgba(7, 38, 56, 1)',
            },
            {
              type: 'line',
              label: 'Profit',
              data: [0, 0, 0, 0, 5, 5, 10, 20, 20, 30, 40, 50, 60, 50, 50, 50, 40, 30, 20, 20, 10, 0, 0, 0],
              backgroundColor: 'rgba(15, 222, 118, 1)',
              borderColor: 'rgba(15, 222, 118, 1)',
            },
            {
              type: 'bar',
              label: 'EV Charging',
              data: [],
              backgroundColor: [
                'rgba(0, 160, 186, 1)',
              ],
              borderWidth: 1,
            },
            {
              type: 'bar',
              label: 'Energy Price',
              data: [],
              backgroundColor: [
                'rgba(246, 202, 27, 1)',
              ],
              borderWidth: 1,
              yAxisID: 'y1',
            },
          ],
        },
        levelTwoChartData: {
          labels: [],
          datasets: [
            {
              type: 'line',
              label: 'Total kWh',
              data: [],
              fill: false,
              backgroundColor: 'rgba(7, 38, 56, 1)',
              borderColor: 'rgba(7, 38, 56, 1)',
            },
            {
              type: 'line',
              label: 'Profit',
              data: [0, 0, 0, 0, 5, 5, 10, 20, 20, 30, 40, 50, 60, 50, 50, 50, 40, 30, 20, 20, 10, 0, 0, 0],
              backgroundColor: 'rgba(15, 222, 118, 1)',
              borderColor: 'rgba(15, 222, 118, 1)',
            },
            {
              type: 'line',
              label: 'Unoptimized Profit line',
              data: [0, 0, 0, 0, 2, 4, 8, 15, 18, 20, 30, 35, 42, 35, 35, 35, 30, 25, 20, 20, 10, 0, 0, 0],
              backgroundColor: 'rgba(0, 0, 0, 1)',
            },
            {
              type: 'bar',
              label: 'EV Charging',
              data: [],
              backgroundColor: [
                'rgba(0, 160, 186, 1)',
              ],
              borderWidth: 1,
            },
            {
              type: 'bar',
              label: 'Energy Price',
              data: [],
              backgroundColor: [
                'rgba(246, 202, 27, 1)',
              ],
              borderWidth: 1,
              yAxisID: 'y1',
            },
            {
              type: 'bar',
              label: 'Unoptimized Charging profile',
              data: [],
              borderDash: [2],
              backgroundColor: 'rgba(0, 0, 0, .1)',
              borderColor: 'rgba(0, 0, 0, 1)',
              borderWidth: 1,
            },
          ],
        },
        levelThreeChartData: {
          labels: [],
          datasets: [
            {
              label: 'Energy used by building',
              data: [],
              backgroundColor: [
                'rgba(7, 38, 56, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'EV Charging',
              data: [],
              backgroundColor: [
                'rgba(0, 160, 186, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'Ev solar charging',
              data: [],
              backgroundColor: [
                'rgba(29, 200, 226, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'Smart charging',
              data: [],
              backgroundColor: [
                'rgba(255, 228, 115, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'Solar Energy',
              data: [],
              backgroundColor: [
                'rgba(246, 202, 27, 1)',
              ],
              borderWidth: 1,
            },
          ],
        },
        levelFourChartData: {
          labels: [],
          datasets: [
            {
              label: 'Energy used by building',
              data: [],
              backgroundColor: [
                'rgba(7, 38, 56, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'EV Charging',
              data: [],
              backgroundColor: [
                'rgba(0, 160, 186, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'Ev solar charging',
              data: [],
              backgroundColor: [
                'rgba(29, 200, 226, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'Smart charging',
              data: [],
              backgroundColor: [
                'rgba(255, 228, 115, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'Solar Energy',
              data: [],
              backgroundColor: [
                'rgba(246, 202, 27, 1)',
              ],
              borderWidth: 1,
            },
          ],
        },
        levelFiveChartData: {
          labels: [],
          datasets: [
            {
              label: 'Energy used by building',
              data: [],
              backgroundColor: [
                'rgba(7, 38, 56, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'EV Charging',
              data: [],
              backgroundColor: [
                'rgba(0, 160, 186, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'Ev solar charging',
              data: [],
              backgroundColor: [
                'rgba(29, 200, 226, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'Smart charging',
              data: [],
              backgroundColor: [
                'rgba(255, 228, 115, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'Solar Energy',
              data: [],
              backgroundColor: [
                'rgba(246, 202, 27, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'Battery Stored Energy',
              data: [],
              backgroundColor: [
                'rgba(15, 222, 118, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'Battery Stored Ev Charging',
              data: [],
              backgroundColor: [
                'rgba(87, 245, 166, 1)',
              ],
              borderWidth: 1,
            },
          ],
        },
        levelSixChartData: {
          labels: [],
          datasets: [
            {
              label: 'Energy used by building',
              data: [],
              backgroundColor: [
                'rgba(7, 38, 56, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'EV Charging',
              data: [],
              backgroundColor: [
                'rgba(0, 160, 186, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'Ev solar charging',
              data: [],
              backgroundColor: [
                'rgba(29, 200, 226, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'Smart charging',
              data: [],
              backgroundColor: [
                'rgba(255, 228, 115, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'Solar Energy',
              data: [],
              backgroundColor: [
                'rgba(246, 202, 27, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'Battery Stored Energy',
              data: [],
              backgroundColor: [
                'rgba(15, 222, 118, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'Battery Stored Ev Charging',
              data: [],
              backgroundColor: [
                'rgba(87, 245, 166, 1)',
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {},
        smartLevelOptions: {
          scales: {
            x: {
              stacked: false,
            },
            y: {
              stacked: false,
            },
          },
        },
      };
    },
    created() {
      this.getBuildingData();
      this.getSolarData();
      this.getEVChargers();
      this.getEStorage();
      this.getTools();

      this.levelZeroFakeData();
      this.getLevelOneData();
      this.getLevelThreeData();
      this.getLevelFourData();
      this.getLevelFiveData();
      this.getLevelSixData();
    },
    mounted() {
      this.interval = setInterval(() => this.getBuildingData(), 1000 * 60);
      this.interval = setInterval(() => this.getSolarData(), 1000 * 60);
      this.interval = setInterval(() => this.getEVChargers(), 1000 * 60);
      this.interval = setInterval(() => this.getEStorage(), 1000 * 60);
    },
    methods: {
      async getBuildingData() {
        this.loading.building = true;
        const response = await axios.get('https://api.grid.com/get/building/data');

        this.buildingData = {
          'kWh used today': response.data[0].data.building['kwh-used-today'].toFixed(2),
        };

        this.loading.building = false;
      },
      async getSolarData() {
        this.loading.solar = true;
        const response = await axios.get(
          'https://grid-solar-edge.azurewebsites.net/usage',
          {
            headers: {
              Authorization: `Bearer AsFHSROyYNeZxSNn67Diki2VpDPX51tp`,
            },
          },
        );

        this.solarData = {
          'Current Power': response.data[0].currentPower.power + response.data[1].currentPower.power,
          'Last Day Data': response.data[0].lastDayData.energy + response.data[1].lastDayData.energy,
          'Last Month Data': response.data[0].lastMonthData.energy + response.data[1].lastMonthData.energy,
        };

        this.loading.solar = false;
      },
      async getEVChargers() {
        this.loading.evChargers = true;
        const response = await axios.get(
          'https://grid-app-api.azurewebsites.net/EnergyConsumption/13',
          {
            headers: {
              Authorization: `Bearer AsFHSROyYNeZxSNn67Diki2VpDPX51tp`,
            },
          },
        );

        this.EVChargersData = {
          'Charge Sessions': response.data.chargeSessions,
          'Estimated Energy Consumed': response.data.estimatedEnergyConsumed,
          'Total Charging Time': response.data.totalChargingTime,
        };

        this.loading.evChargers = false;
      },
      async getEStorage() {
        this.loading.eStorage = true;
        const response = await axios.get(`https://api.grid.com/get/battery/data`);
        this.eStorageData = {
          Current: `${response.data.data['stack-current'].toFixed(1)} A`,
          Soc: `${response.data.data['stack-soc']} %`,
          Voltage: `${response.data.data['stack-voltage'].toFixed()} V`,
        };

        this.loading.eStorage = false;
      },
      async getTools() {
        const response = await axios.get('https://tools.gmu.online/grid/epex/index.php');
        const key = Object.keys(response.data.data)[0];
        this.graphAverageValues = response.data.data[key];

        response.data.data[key].forEach(item => {
          this.graphAverage += Number(item.value);

          const time = new Date(item.date).toLocaleString('en-US', { hour: 'numeric', hour12: true });
          this.data.labels.unshift(time);
          this.data.datasets[0].data.unshift(item.value);
          this.levelOneChartData.datasets[3].data.unshift(item.value);
          this.levelTwoChartData.datasets[4].data.unshift(item.value);
        });

        this.graphAverage /= response.data.data[key].length;
      },
      async fallbackData() {
        this.buildingData = {
          'kWh used today': 50,
        };

        this.solarData = {
          'Current Power': 1127468.13,
          'Last Day Data': 5146641,
          'Last Month Data': 131346096,
        };

        this.EVChargersData = {
          'Charge Sessions': 2,
          'Estimated Energy Consumed': 0.066,
          'Total Charging Time': 0,
        };

        this.eStorageData = {
          Current: `${3.6} A`,
          Soc: `${90.25} %`,
          Voltage: `${720} V`,
        };

        this.loading = {
          building: false,
          evChargers: false,
          solar: false,
          eStorage: false,
        };
      },
      chartDataHandler(mainObj, dataArray) {
        for (const value of Object.values(mainObj)) {
          dataArray.push(value);
        }
      },
      chartFakeDataHandler(mainObj, dataArray) {
        for (const value of Object.values(mainObj)) {
          console.log(value);
          dataArray.push(value - 15);
        }
      },
      chartKeyHandler(mainObj, dataArray) {
        for (const key of Object.keys(mainObj)) {
          dataArray.labels.push(key);
        }
      },
      levelZeroFakeData() {
        for (let i = 0; i < 24; i += 1) {
          this.levelZeroChartData.datasets[0].data.push(i * 10);
          this.levelOneChartData.datasets[0].data.push(i * 10);
          this.levelTwoChartData.datasets[0].data.push(i * 10);
        }
      },
      async getLevelOneData() {
        const response = await axios.get('https://api.grid.com/get/smart-level-1');
        const main = response.data.data;

        this.chartKeyHandler(main['ev-charging'], this.levelZeroChartData);
        this.chartKeyHandler(main['ev-charging'], this.levelOneChartData);
        this.chartKeyHandler(main['ev-charging'], this.levelTwoChartData);
        this.chartDataHandler(main['ev-charging'], this.levelZeroChartData.datasets[1].data);
        this.chartDataHandler(main['ev-charging'], this.levelOneChartData.datasets[2].data);
        this.chartDataHandler(main['ev-charging'], this.levelTwoChartData.datasets[3].data);
        this.chartFakeDataHandler(main['ev-charging'], this.levelTwoChartData.datasets[5].data);
      },
      async getLevelThreeData() {
        const response = await axios.get('https://api.grid.com/get/smart-level-3');
        const main = response.data.data;
        const chartData = num => this.levelThreeChartData.datasets[num].data;

        this.chartKeyHandler(main.eubb, this.levelThreeChartData);
        this.chartDataHandler(main.eubb, chartData(0));
        this.chartDataHandler(main['ev-charging'], chartData(1));
        this.chartDataHandler(main['ev-solar-charging'], chartData(2));
        this.chartDataHandler(main['smart-charging'], chartData(3));
        this.chartDataHandler(main['solar-energy'], chartData(4));
      },
      async getLevelFourData() {
        const response = await axios.get('https://api.grid.com/get/smart-level-4');
        const main = response.data.data;
        const chartData = num => this.levelFourChartData.datasets[num].data;

        this.chartKeyHandler(main.eubb, this.levelFourChartData);
        this.chartDataHandler(main.eubb, chartData(0));
        this.chartDataHandler(main['ev-charging'], chartData(1));
        this.chartDataHandler(main['ev-solar-charging'], chartData(2));
        this.chartDataHandler(main['smart-charging'], chartData(3));
        this.chartDataHandler(main['solar-energy'], chartData(4));
      },
      async getLevelFiveData() {
        const response = await axios.get('https://api.grid.com/get/smart-level-5');
        const main = response.data.data;
        const chartData = num => this.levelFiveChartData.datasets[num].data;

        this.chartKeyHandler(main.eubb, this.levelFiveChartData);
        this.chartDataHandler(main.eubb, chartData(0));
        this.chartDataHandler(main['ev-charging'], chartData(1));
        this.chartDataHandler(main['ev-solar-charging'], chartData(2));
        this.chartDataHandler(main['smart-charging'], chartData(3));
        this.chartDataHandler(main['solar-energy'], chartData(4));
        this.chartDataHandler(main['battery-stored-energy'], chartData(5));
        this.chartDataHandler(main['battery-stored-ev-charging'], chartData(6));
      },
      async getLevelSixData() {
        const response = await axios.get('https://api.grid.com/get/smart-level-6');
        const main = response.data.data;
        const chartData = num => this.levelSixChartData.datasets[num].data;

        this.chartKeyHandler(main.eubb, this.levelSixChartData);
        this.chartDataHandler(main.eubb, chartData(0));
        this.chartDataHandler(main['ev-charging'], chartData(1));
        this.chartDataHandler(main['ev-solar-charging'], chartData(2));
        this.chartDataHandler(main['smart-charging'], chartData(3));
        this.chartDataHandler(main['solar-energy'], chartData(4));
        this.chartDataHandler(main['battery-stored-energy'], chartData(5));
        this.chartDataHandler(main['battery-stored-ev-charging'], chartData(6));
      },
    },
  };
</script>
